.style-span{
    font-weight: bold !important; 
    line-height: 30px !important;
}

.container-span{
    padding-left: 0px;
}

.container-shop-section{
    text-align: left;
    justify-content: left;
}

.format-span{
    margin-top: 7px;
}