.Modal {
  display: none; 
  position: fixed;  
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%;  
  height: 100%;  
  background-color: rgba(0,0,0,0.4);  
  z-index: 1000;
  padding-top: 25vh;
}

.modal-body{
  display: flex;
  justify-content: flex-end;
}

#btn-cancel{
  height: 38px;
  width: 100px;
  background-color: var(--redCoriport);
  color: white;
  margin: 6px;
}
#btn-cancel:hover{
  color: white;
  transition: box-shadow .3s;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.493); 
}

#btn-success{
  height: 38px;
  width: 100px;
  background-color: var(--greenCoriport);
  color: white;
  margin: 6px;
}
#btn-success:hover{
  color: white;
  transition: box-shadow .3s;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.493); 
}

.modal-invoice{ 
  margin-top: -151px;

} 

.container-modal-invoice{
  padding-top: -30px;
}

.scroll-modal{
  width: 100%;
  height: 65vh;
  overflow: auto;
}

#container-section{
  margin-bottom: 15px !important;
}

.container-modal-shop{
  text-align: left;
  justify-content: left;
  padding: 0px;
}

.cursor-pointer{
  cursor: pointer;
}

#sections{
  background-color: white;
  height: 375px;
  overflow: scroll;
  overflow-X: hidden;
}
 
.custom-title-modal{
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}

#buttons-modal-delete{
  width: 98.57px;
  height: 38px;
}