.full-height {
    height: 50vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.code {
    border-right: 2px solid;
    font-size: 30px;
    padding: 0 15px 0 15px;
    text-align: center;
    color: var(--redCoriport);
}

.message {
    font-size: 26px;
    text-align: center;
    padding-left: 10px;
    color: var(--blueCoriport);
}