.notification{
	width: 60vw;
	height: 40px;
	border-radius: 0px 0px 5px 5px;
	margin: 0 auto;
	box-shadow: #95a5a6 0px 0px 6px 2px;
	color: white;
	line-height: 40px;
	overflow: hidden;
	animation: reveal 1 2s;
}
.error-notification{
    opacity: 1; 
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    min-height: 3.75rem;
    background-image: linear-gradient(195deg, rgb(239, 83, 80), rgb(229, 57, 53));
    color: rgb(255, 255, 255);
    position: relative;
    padding: 1rem;
    margin-bottom: 3rem;
    border-radius: 0.375rem;
    font-weight: 600;
}

.success-notification{
    opacity: 1; 
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    min-height: 3.75rem;
    background:  #2ecc71;
    color: rgb(255, 255, 255);
    position: relative;
    padding: 1rem;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    margin-right: 1em;
    border-radius: 0.375rem;
    font-weight: 600;
    width: 20rem;
    opacity: 0;
  animation: fade-in 1s forwards;
}

.warning-notification{
    opacity: 1; 
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    min-height: 3.75rem;
    background:  #f1c40f;
    color: rgb(255, 255, 255);
    position: relative;
    padding: 1rem;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    margin-right: 1em;
    border-radius: 0.375rem;
    font-weight: 600;
    width: 20rem;
    opacity: 0;
  animation: fade-in 1s forwards;
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@media only screen and (max-width: 600px) {
    .success-notification {
        margin-right: 0;
    }
  }
.container-success{
    display: flex;
    justify-content: flex-end;
}
.container-error{
    display: flex;
    justify-content: flex-end;
}
.notification-text{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    opacity: 1;
    background: transparent;
    color: rgb(255, 255, 255);
    box-shadow: none;

}
.notification-text p{
    margin: 0px;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.01071em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 500;
}
.notification-x {
    color: rgb(255, 255, 255);
    font-size: 1.25rem;
    padding: 0.5625rem 0.375rem 0.5rem;
    margin-left: 2.5rem;
    font-weight: 600;
    cursor: pointer;
    line-height: 0;
}
.notification .title{
	margin-right: 15px;
	padding: 0px 15px;
	line-height: 40px;
	display: inline-block;
}

.notification .close{
	background: rgba(255,255,255,0.2);
	padding: 0px 15px;
	float: right;
	line-height: 40px;
	display: inline-block;
	color: white;
}
.notification .close:hover{
  cursor: pointer;
}
.notification.closed{
	transform: translate(0px, -50px);
	transition: 0.7s;
}

@keyframes reveal{
	0%{
		transform: translate(0px, -50px);
	}
	50%{
		transform: translate(0px, -50px);
	}
	100%{
		transform: translate(0px, 0px);
	}
}


.notification.success{
	background: #2ecc71;
}
.notification.success .title{
	background: #27ae60;
}

.notification.error{
	background: #e74c3c;
}
.notification.error .title{
	background: #c0392b;
}

.notification.warning{
	background: #f1c40f;
}
.notification.warning .title{
	background: #f39c12;
}

.notification.normal{
	background: #3498db;
}
.notification.normal .title{
	background: #2980b9;
}