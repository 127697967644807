.combo-container {
    margin-top: 20px;
}
.custom_select__control {
    border-style: none;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
}

.custom_select__value-container {
    height: 35px;
}

.basic-select .custom_select__control--is-focused {
    color: #495057;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(6 38 117 / 25%);
}

.basic-select .custom_select__control--is-focused:hover {
    color: #495057;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(6 38 117 / 25%);
}