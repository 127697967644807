.form-control.input-form {
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
    height: 37px;
    margin-top: 20px;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-spinner-loading {
    margin-top: -12px;
}

.bg-button {
    background-image: linear-gradient(195deg, rgb(27, 115, 192), rgb(16, 73, 148));
}

.container-login {
    width: 23rem;
    margin-top: 13rem; 
}

.image-login {
    object-fit: cover;
    object-position: left;
}