.wrapper-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container-card {
  overflow: hidden;
  box-shadow: 0px 2px 8px 0px var(--clr-gray-light);
  background-color: white;
  text-align: center;
  border-radius: 1rem;
  position: relative;
  width: 280px;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.border-green {
  border: 1px solid var(--greenCoriport);
}

.border-red {
  border: 1px solid var(--redCoriport);
}

.name {
  font-weight: bold;
  font-size: 1.5rem;
}


/* rotation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-ms-keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  position: relative;
  background-color: transparent;
  margin: 40px auto 0 auto;
  height: 170px;
  width: 200px;
  left: 30%;
  top: 60px;
}

.circle {
  /* Draw circles */
  border-radius: 50%;

}

.x {
  display: block;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
  background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7);
  background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
  color: #a7a7a7;
  margin: 36px;
  width: 44px;
  height: 44px;
  position: relative;
  text-align: center;
  line-height: 144px;
  border-radius: 50%;
  box-shadow: 0px 3px 10px #aaa, inset 0px 2px 4px #fff;
}

.check-circle {
  position: relative;
  top: -40px;
  fill: var(--greenCoriport);
}

.check-circle-red {
  position: relative;
  top: -38px;
  left: 6px;
  fill: var(--redCoriport);
  width: 55px;
  height: 55px;

}

/* monitor */
.monitor {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -120px 0 0 -120px;
  height: 70px;
  width: 70px;
  background-color: #F2F0F0;
  cursor: pointer;
}
.monitor .buffering {
  position: absolute;
  top: -25px;
  left: -25px;
  width: 120px;
  height: 120px;
  border-right: 10px solid rgba(188, 243, 209, 0.6);
  border-bottom: 10px solid var(--greenCoriport);
  border-left: 10px solid rgba(188, 243, 209, 0.6);
  border-top: 10px solid var(--greenCoriport);
  animation: rotate 5s infinite linear;
}

.monitor .buffering-red {
  position: absolute;
  top: -25px;
  left: -25px;
  width: 120px;
  height: 120px;
  border-right: 10px solid rgba(243, 188, 188, 0.6);
  border-bottom: 10px solid var(--redCoriport);
  border-left: 10px solid rgba(243, 188, 188, 0.6);
  border-top: 10px solid var(--redCoriport);
  animation: rotate 5s infinite linear;
}
 