.form-color{
    background-color: #f7fafc;
    padding: 50px;
    border-radius: 4px;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
    margin-bottom: 50px;
}
.form-label{
    display: block;
    text-decoration: none;
    color: black;
    font-size: 15px;
    line-height: 30px;
    font-weight: 500;
    margin-left: 3px;
    margin-bottom: -20px;
}
.btn-form{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    background-color: var(--blueGradiant);
}
.acction-card {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 10rem;
    height: 4rem;
    margin-top: -84px;
    margin-bottom: 50px;
    opacity: 1;
    background: var(--blueGradiant);
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}


#checkbox{
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
}