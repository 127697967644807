.topnav {
    background:  var(--blueGradiant);
    overflow: hidden;
    height: auto;
    top: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .title-container{
      width: 30%;
  }
  .user-icon-container {
    width: 30%;
    margin-right: 40px; 
    align-content: flex-end;
  }
  .svg-icon-user{
    fill: white;
    color: white;
  }
  .logout-icon{
    fill: white;
    color: white;  
    width: 23px;
    height: 23px;
    margin-left: 10px; 
    margin-bottom: 1px;
  }
 
  