.numbers-document{
    margin-top: 15px;
    margin-bottom: 30px;
}
.shop-name{
    margin-top: 15px;
} 
.text-amounts{
    width: 120px;
    padding-right: 15px;
}

#not-records{
    margin-top: 50px;
    width: 100%;
    justify-content: center;
    text-align: center;
}