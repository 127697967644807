/* Toggle Styles */ 
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  background:  var(--blueGradiant);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  box-shadow: 10px 0 5px -2px #888; 
}

#wrapper.toggled #sidebar-wrapper {
  width: 248px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 10px;
  background-color: rgb(240, 242, 245);
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  padding-bottom: 0px;
  list-style: none;
}

.sidebar-nav .sidebar-item{
  height: 60px;
  margin-bottom: 10px;
}
.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 14px;
  line-height: 50px;
  font-weight: 500;
  justify-content: center;
  border-radius: 10px;
}

ul#nav li a:hover,ul#nav li.active a{
  color: #36f;
  background-color: white;
  width: 100%;
  height: 100%;
 }

 .sidebar-nav{
   padding-bottom: 30px ;
 }

 ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.sidebar-nav .sidebar-item a:hover {
  text-decoration: none;
  background: rgba(255,255,255,0.2);
  height: 60px;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}
.sidebar-svg-icon{
  width: 25px;
  height: 25px;
  fill: currentColor;
  color: currentColor;
  margin-right: 12px;
  margin-top: -5px;
}
.sidebar-svg-icon:hover{
  fill: #36f;
  color: #36f;
} 
.item{
   display: flex;
   position: relative;
   align-items: center;
   justify-content: flex-start;
}
.item-name{
  position: relative;
  margin-top: 6px;
  left: -10px;
}

.sidebar-nav > .sidebar-brand {
  height: 150px;
  font-size: 18px;
  line-height: 60px;
  background-color: white;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}
hr.solid{
  border-top: 2px solid white;
}

.sidebar-nav > .sidebar-brand a:hover {
  background: none!important;
}

@media(min-width:768px) {
  #wrapper {
      padding-left: 250px;
  }

  #wrapper.toggled {
      padding-left: 0;
  }

  #sidebar-wrapper {
    
      width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
      width: 0;
  }

  #page-content-wrapper {
      padding: 20px;
      position: relative;
      min-height: 850px;
  }

  #wrapper.toggled #page-content-wrapper {
      position: relative;
      margin-right: 0;
  }
}

.item{
  list-style: none;
  margin-left: 12px;
  padding-left: 0px;
  width: 212px;
}
 
#nav-item{
  width: 240px;
  margin-left: 6px;
  padding: 20px;
  margin-bottom: -30px;
  padding-top: 20px;
  padding-left: 7px;
  cursor: pointer;
}

#nav-item-sub-menu{ 
  padding-top: 20px;
  padding-right: 24px;
  padding-bottom: 20px;
  padding-left: 12px;
  cursor: pointer;
} 

.container-sub-menu{
  margin-left: -50px;
}
 
.submenu{
  margin-top: 5px;
  width: 180px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#option{
  padding-left: 5px;
}

.list-item{
  margin-top: 50px;
}

#item-sub-menu{
  margin-top: 10px;
}

#link-item-sub-menu{
  padding-right: 15px;
}
 
.option-configuration{
  margin-top: -30px; 
}
 
#container-item{
  cursor: pointer;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 20px;
}

.container-sub-item{
  cursor: pointer;
  list-style: none;
  padding-left: 5px;
  padding-top: 10px;
  padding-right: -5px;
}

#sub-item{
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.sub-item{
  padding-left: 10px;
  padding-right: 20px;
}

.label-item{
  font-size: 15px;
}

.nav-link{
  padding-left: 4px;
}