:root {
  --blueCoriport:  rgba(0,73,145,255);
  --blueGradiant:  linear-gradient(
    195deg
    , rgb(27, 115, 192), rgb(16, 73, 148));
  --white: #faf0e6;
  --grayCoriport: #636569;
  --redCoriport: rgba(239,27,45,255);
  --greenCoriport: rgba(113,190,69,255);
  --orangeCoriport: 	#FF8C00;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  color: white;
  box-sizing: border-box;
  font-family: monospace;
  font-size: 15px;
}
.help.is-danger {
  color: #cc0033;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    'header'
    'main'
    'footer';
}

.header {
  grid-area: header;
  background: var(--blueGradiant);
}
.main {
  grid-area: main;
  background-color: rgb(240, 242, 245);
}
.footer {
  grid-area: footer;
  background-color: whitesmoke;
}

/* flexing header and footer*/
.header,
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: darkblue;
  padding: 0 15px;
}

/* Layout for main content overview  and its cards*/
.main_overview {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid lightgreen;
}
.overview_card {
  flex-basis: 250px;
  flex-grow: 1;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* background-color: seagreen; */
  height: 100px;
  border: 1px solid darkblue;
  border-radius: 4px;
  color: darkblue;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  background-color: white;
}
/* Layout for main-cards section // below main_overview */
.main_cards {
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr;
  min-height: 500px;
  grid-template-areas:
    'card1';
  grid-gap: 10px;
  background-color:  rgb(240, 242, 245);
  
  
}
.card {
  padding: 20px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}

.card:first-child {
  grid-area: card1;
  background-color: #f7f3f3;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;

}
/* responsive layout */
@media only screen and (min-width: 750px) {
  .grid-container {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: 50px 1fr 50px;
    grid-template-areas:
      'header'
      'main'
      'footer';
    height: 100vh;
    
  }

  .main_cards {
    margin: 10px;
    display: grid;
  }
}

.header_title {
  margin-left: 20px;
 padding-top: 10px;
 padding-bottom: 10px;

}

.header_avatar__svgicon{
  fill: white;
  color: white;
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
}
.header__menu-icon-color{
  fill: white;
  color: white;
  bottom: 115px;
}
.header_avatar__username{
  cursor: pointer;
  color: white;
  font-size: 1rem;
  margin-left: 10px;
}

.aside_close-icon {
  position: absolute;
  visibility: visible;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.header__span{
  display: none;
}
@media only screen and (min-width: 750px) {
  .aside_close-icon {
    display: none;
  }
  .header__span{
    color: white;
    font-size: 1rem;
    display: inline;
  }

}

.capitalize-text {
  text-transform: capitalize;
  font-size: 12px;
  margin-top: 18px;
}