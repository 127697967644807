.header-table {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: -45px;
  padding: 24px 16px;
  opacity: 1;
  background: var(--blueGradiant);
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.data-table-extensions {
  margin-bottom: 14px;
  margin-top: 5px;
}

.card-table {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  overflow: visible;
  padding-left: 50px;
  padding-right: 50px;
}

.table-title {
  margin: 0px;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(255, 255, 255);
}

.monitor-status {
  height: auto;
  padding: 0.55em 0.9em;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 0.375rem;
  background: var(--greenCoriport);
  color: rgb(255, 255, 255);
  position: static;
  transform: none;
}

.active-status {
  cursor: pointer;
  height: auto;
  padding: 0.55em 0.9em;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 0.375rem;
  background: var(--greenCoriport);
  color: rgb(255, 255, 255);
  position: static;
  transform: none;
  width: 58.9px;
}

.active-status:hover {
  transform: scale(1.1);
}

.active-status-monitor {
  cursor: pointer;
  height: auto;
  padding: 0.55em 0.9em;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 0.375rem;
  background: var(--greenCoriport);
  color: rgb(255, 255, 255);
  position: static;
  transform: none;
  width: 58.9px;
}

.att-nft-status {
  height: auto;
  padding: 0.55em 0.9em;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 0.375rem;
  background: var(--blueGradiant);
  color: rgb(255, 255, 255);
  position: static;
  transform: none;
}

.inactive-status {
  height: auto;
  padding: 0.55em 0.9em;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 0.375rem;
  background: red;
  color: rgb(255, 255, 255);
  position: static;
  transform: none;
}

.inactive-status-monitor {
  height: auto;
  padding: 0.55em 0.9em;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 0.375rem;
  background: red;
  color: rgb(255, 255, 255);
  position: static;
  transform: none;
}

.orange-status-monitor {
  height: auto;
  padding: 0.55em 0.9em;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 0.375rem;
  background: orange;
  color: rgb(255, 255, 255);
  position: static;
  transform: none;
}

.installed-status {
  cursor: pointer;
  height: auto;
  padding: 0.55em 0.9em;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 0.375rem;
  background: var(--greenCoriport);
  color: rgb(255, 255, 255);
  position: static;
  transform: none;
  width: 80px;
}

.uninstalled-status {
  cursor: pointer;
  height: auto;
  padding: 0.55em 0.9em;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 0.375rem;
  background: var(--greenCoriport);
  color: rgb(255, 255, 255);
  position: static;
  transform: none;
  width: 80px;
  background: red;
}

.botton-delete {
  margin-left: -25px !important;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  left: 0;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  max-width: 64px !important;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  background-color: transparent;
  min-height: 2.3125rem;
  box-shadow: none;
  padding: 0.5625rem 1.5rem;
  fill: var(--redCoriport);
  color: var(--redCoriport);
  background-size: 150% !important;
  background-position-x: 25% !important;
}

.botton-delete:hover {
  color: var(--redCoriport);
}

.botton-edit {
  margin-left: -30px !important;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  max-width: 64px !important;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  background-color: transparent;
  min-height: 2.3125rem;
  box-shadow: none;
  padding: 0.5625rem 1.5rem;
  fill: var(--orangeCoriport) !important;
  color: var(--orangeCoriport) !important;
  background-size: 150% !important;
  background-position-x: 25% !important;
}

.botton-edit:hover {
  color: var(--orangeCoriport) !important;
}

.botton-modal-invoice {
  margin-left: -30px !important;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  max-width: 64px !important;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  background-color: transparent;
  min-height: 2.3125rem;
  box-shadow: none;
  padding: 0.5625rem 1.5rem;
  background-size: 150% !important;
  background-position-x: 25% !important;
}

.botton-modal-invoice:hover {
  color: var(--orangeCoriport) !important;
}

.botton-find {
  /* margin-left: -10px; */
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  max-width: 64px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  background-color: transparent;
  min-height: 2.3125rem;
  box-shadow: none;
  padding: 0.5625rem 1.5rem;
  background-size: 150% !important;
  background-position-x: 25% !important;
}

.container-bottons {
  margin-top: 30px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  align-items: center;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
}

#custom-btn-coriport {
  box-sizing: border-box;
  border: 0px;
  border-radius: 0.5rem;
  outline: none;
  box-shadow: none;
}

.label-button-add {
  margin-top: -1px;
}
 
.btn-coriport {
  height: 0px;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin-left: 20px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  min-width: 64px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2.3125rem;
  padding: 0.5625rem 1.5rem;
  color: white;
}

.btn-add {
  background-image: var(--blueGradiant);
  margin-bottom: 5px;
  width: 115px;
}


.btn-cancel {
  background-color: red;
  width: 115px;
  margin-bottom: 5px;
}

.loading {
  display: inline-block;
  position: relative;
  width: 84px;
  height: 84px;
}

.loading div {
  position: absolute;
  background: var(--greenCoriport);
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div:nth-child(2) {
  animation-delay: -.7s;
}

@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.led-box {
  height: 30px;
  width: 25%;
  margin: 10px 0;
  float: left;
}

.led-red {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  background-color: var(--redCoriport);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 0.5s infinite;
  -moz-animation: blinkRed 0.5s infinite;
  -ms-animation: blinkRed 0.5s infinite;
  -o-animation: blinkRed 0.5s infinite;
  animation: blinkRed 0.5s infinite;
}

@-webkit-keyframes blinkRed {
  from {
    background-color: var(--redCoriport);
  }

  50% {
    background-color: rgb(212, 82, 82);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #af4646 0 -1px 9px, rgba(163, 18, 18, 0.5) 0 2px 0;
  }

  to {
    background-color: var(--redCoriport);
  }
}

@-moz-keyframes blinkRed {
  from {
    background-color: var(--redCoriport);
  }

  50% {
    background-color: rgb(212, 82, 82);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #af4646 0 -1px 9px, rgba(163, 18, 18, 0.5) 0 2px 0;
  }

  to {
    background-color: var(--redCoriport);
  }
}

@-ms-keyframes blinkRed {
  from {
    background-color: var(--redCoriport);
  }

  50% {
    background-color: rgb(212, 82, 82);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #af4646 0 -1px 9px, rgba(163, 18, 18, 0.5) 0 2px 0;
  }

  to {
    background-color: var(--redCoriport);
  }
}

@-o-keyframes blinkRed {
  from {
    background-color: var(--redCoriport);
  }

  50% {
    background-color: rgb(212, 82, 82);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #af4646 0 -1px 9px, rgba(163, 18, 18, 0.5) 0 2px 0;
  }

  to {
    background-color: var(--redCoriport);
  }
}

@keyframes blinkRed {
  from {
    background-color: var(--redCoriport);
  }

  50% {
    background-color: rgb(212, 82, 82);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #af4646 0 -1px 9px, rgba(173, 31, 31, 0.5) 0 2px 0;
  }

  to {
    background-color: var(--redCoriport);
  }
}


.actions-buttons {
  display: flex;
  justify-content: center;
}

.container-not-record {
  margin-top: 90px;
  margin-bottom: 80px;
}

#find-product {
  margin-left: -30px;
}

.container-search-button {
  padding-left: 30px;
  padding-right: 20px;
  align-items: stretch;
  justify-content: space-between;
  margin-top: -10px;
}

#format-amounts {
  text-align: right !important;
  min-width: 100px;
}

.lnndaO {
  font-size: 15px;
}

#container-input-search {
  padding-left: 1.8rem;
  padding-right: 0.1rem;
}

#label-checkBox {
  font-weight: 600;
}

#ena-status {
  padding-right: 19px;
  padding-left: 19px;
}

#dis-status {
  padding-right: 14.1px;
  padding-left: 14.1px;
}

#att-status {
  padding-right: 12.5px;
  padding-left: 12.5px;
}

.disabled-cursor-pointer{
  cursor: text;
}