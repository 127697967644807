.client-name-report {
    margin-top: 20px;
}

.period-name-report {
    margin-top: -20px;
}

.container-dates-report {
    margin-top: 35px;
}

.container-bottons-report {
    margin-top: 38px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items: center;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
    text-align: right;
    justify-content: right;
}

.container-dates {
    padding-left: 23px;
}

.download {
    outline: none;
    box-shadow: none;
}

input[type="file"]::file-selector-button {
    background-image: var(--blueGradiant);
    margin-bottom: 5px;
    width: 145px;
    border: none;
    height: 0px;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    min-width: 64px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    border-radius: 0.5rem;
    line-height: 1.4;
    text-align: center;
    user-select: none;
    transition: all 150ms ease-in 0s;
    min-height: 2.3125rem;
    padding: 0.5625rem 1.5rem;
    color: white;
}